<template>
  <Room />
</template>

<script>
export default {
  name: "RoomIdPage",
  components: {
    Room: () => import("~cp/Room/Room"),
  },
};
</script>

<style></style>
